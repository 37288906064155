import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

import Select from "react-select";
import Input from "../Input/Input";
import requests from "../../services/requests";
import api from "../../services/api";

import './style.css';

const yup = require("yup");

const ContentCertificate = ({ entity }) => {
    const [load, setLoad] = useState(false);
    const [selectIsDisabled, setSelectIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [newFile, setNewFile] = useState();
    const [fileBlob, setFileBlob] = useState();
    const [clientsOptions, setClientsOptions] = useState([]);
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");

    function getCertificateList() {
        setLoad(true);

        let filters = {};
        if (entity?.documentNumber) {
            filters = {
                documentNumber: entity.documentNumber
            }
        }

        requests.getCertificateList(filters).then(res => {
            setLoad(false);
            setList(res.data);
        });
    }

    function handleInputFile(e) {
        const file = e.target.files[0];

        setNewFile(file);
        setFileBlob(URL.createObjectURL(file));
    }

    const loadClientsList = (search = "") => {
        setNoClientOptionsMessage('Carregando')
        setIsLoadingClients(true)

        if (entity) {
            search = entity.name;
            setSelectIsDisabled(true);
        }

        if (search.length < 2) {
            setNoClientOptionsMessage('Insira ao menos 2 caracteres para fazer a busca')
            setIsLoadingClients(false)
        } else {
            api.get(`client/list?page_size=10000${search && `&search=${search}`}`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            })
                .then(response => {
                    var clients = [];

                    response.data.data.forEach(item => {
                        clients.push({
                            value: item.identifier,
                            label: item.name + ' (' + item.documentNumberFormatted + ')',
                        });
                    });

                    setClientsOptions(clients);
                    setNoClientOptionsMessage('Nenhum resultado encontrado')
                })
                .finally(() => {
                    setLoad(false);
                    setIsLoadingClients(false)
                });
        }
    };

    useEffect(() => {
        getCertificateList();
        loadClientsList();
    }, [entity]);

    return (
        <>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            password: '',
                            client: entity?.identifier ? entity?.identifier : '',
                        }}
                        validationSchema={yup.object().shape({
                            password: yup.string().required("Campo obrigatório"),
                            client: yup.string().required("Campo obrigatório"),
                        })}
                        onSubmit={(values, { setFieldError, setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            if (!newFile) {
                                toast.error("Anexe o arquivo!");
                                setSubmitting(false);
                                return;
                            }

                            const data = new FormData();
                            data.append("client_certificate_file_upload[file]", newFile);
                            data.append("client_certificate_file_upload[password]", values.password);
                            data.append("client_certificate_file_upload[client]", values.client);

                            requests
                                .uploadClientCertificateFile(data)
                                .then((res) => {
                                    setSubmitting(false);
                                    toast.success("Arquivo enviado com sucesso");

                                    getCertificateList();
                                    resetForm({});
                                })
                                .catch((res) => {
                                    setSubmitting(false);
                                    toast.error("Falha no upload, tente novamente.")
                                })
                                ;
                        }}>
                        {({
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            errors,
                            values,
                            touched,
                            handleBlur,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="mx-40">
                                    <label htmlFor="name" className="text-roxo_oficial font-bold">Cliente</label>
                                    <Select
                                        options={clientsOptions}
                                        isDisabled={selectIsDisabled}
                                        id="client"
                                        name="client"
                                        value={clientsOptions.filter(option => option.value === values.client)}
                                        onChange={item => {
                                            setFieldValue("client", item?.value);
                                        }}
                                        noOptionsMessage={() => noClientOptionsMessage}
                                        onInputChange={inputValue => {
                                            loadClientsList(inputValue);
                                        }}
                                        isLoading={isLoadingClients}
                                        className="outline-none transform-none text-black"
                                        placeholder="Selecione o cliente"
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="text-red mb-4 font-light text-xs"
                                        name="client"
                                    />
                                </div>
                                <div className="mx-40 mt-2">
                                    <label htmlFor="name" className="text-roxo_oficial font-bold">
                                        Senha do arquivo
                                    </label>
                                    <Input name="password" type="text" value="" />
                                    <ErrorMessage
                                        component="label"
                                        name="password"
                                        className="text-red font-light w-full"
                                    />
                                </div>
                                <br />
                                <div className="flex flex-col items-center bg-white z-50">
                                    <label htmlFor="file">
                                        <input
                                            id="image"
                                            type="file"
                                            accept=".p12"
                                            onChange={handleInputFile}
                                        />
                                    </label>
                                </div>
                                <div className="flex flex-col items-center bg-white z-50">
                                    <button
                                        className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md mt-10"
                                        type="submit"
                                        disabled={isSubmitting}>
                                        {isSubmitting ? (
                                            <>
                                                Enviando <ClipLoader size={10} loading={isSubmitting} />
                                            </>
                                        ) : (
                                            <>Enviar</>
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </>
            )}

            <hr />

            <div className="overflow-auto">
                <table className="table-auto mt-10 w-full text-left">
                    <thead className="border-b border-roxo_oficial">
                        <tr>
                            <th className="text-roxo_oficial">Arquivo</th>
                            <th className="text-roxo_oficial">Cliente</th>
                            <th className="text-roxo_oficial">Enviado por</th>
                            <th className="text-roxo_oficial">Data de Expiração</th>
                            <th className="text-roxo_oficial">Data de Criação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, index) => (
                            <tr key={item.identifier} className={`${index % 2 === 0 ? "stripped" : ""}`}>
                                <td>
                                    {item.uploadName}
                                    {item.isExpired ? (
                                        <>
                                            <br />
                                            <span className="whitespace-no-wrap text-red">
                                                (Arquivo expirado)
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <br />
                                            <span className="whitespace-no-wrap text-green-600">
                                                (Arquivo válido)
                                            </span>
                                        </>
                                    )}
                                </td>
                                <td>
                                    {item.client.name}
                                    <span className="ml-2 whitespace-no-wrap text-C4">
                                        {`(${item.client.documentNumberFormatted})`}
                                    </span>
                                </td>
                                <td>{item.uploadedByUser.name}</td>
                                <td>{item.expiresAtFormatted}</td>
                                <td>{item.createdAtFormatted}</td>
                            </tr>
                        ))}
                        {list.length === 0 && !loading && (
                            <tr>
                                <td colSpan="10" className="text-center pt-4">
                                    Nenhum arquivo enviado
                                </td>
                            </tr>
                        )}
                        {loading && (
                            <tr>
                                <td colSpan="10" className="text-center pt-4">
                                    <ClipLoader />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ContentCertificate;
