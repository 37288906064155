import ClipLoader from "react-spinners/ClipLoader";
import ContentCertificate from "../../../components/Certificate";
import Input from "../../../components/Input/Input";
import InputMasked from "../../../components/Input/InputMasked";
import Modal from "../../../components/Modals";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import requests from "../../../services/requests";
import Select from "react-select";
import { FaQuestionCircle } from "react-icons/fa";
import { Formik, ErrorMessage } from "formik";
import { toast } from "react-toastify";

const yup = require("yup");

const ModalEdit = ({ tabModal, setTabModal, actionModal, openModal, selectedEntity, handleSuccess }) => {
    const [load, setLoad] = useState(false);
    const [entity, setEntity] = useState(null);
    const [parentOptions, setParentOptions] = useState([]);

    useEffect(() => {
        if (openModal) {
            setLoad(true);
            if (selectedEntity && selectedEntity.identifier) {
                requests.showClient(selectedEntity.identifier).then(data => {
                    setEntity(data);
                    setLoad(false);
                });
            }
            loadParent();
            ReactTooltip.rebuild();
        }
    }, [selectedEntity]);

    const loadParent = async () => {
        requests
            .listClientsManage(
                {
                    isRootDocumentNumber: 1,
                },
                null,
                999999
            )
            .then(list => {
                let arr = [];
                list.data.map(client => {
                    arr.push({
                        label: client.name + " (" + client.documentNumberFormatted + ")",
                        value: client.identifier,
                    });
                });
                setParentOptions(arr);
            });
    };

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-8/12 min-h-20%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5
                        onClick={() => setTabModal("Editar perfil")}
                        className={`mr-6 cursor-pointer ${
                            tabModal === "Editar perfil" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                        }`}>
                            Editar perfil
                    </h5>
                     {entity?.type === "Filial" && (
                        <h5
                            onClick={() => {
                                setTabModal("Upload de certificado");
                            }}
                            className={`mr-6 cursor-pointer ${
                                tabModal === "Upload de certificado" ? "text-roxo_oficial font-bold" : "text-C6 font-normal"
                            }`}>
                                Upload de certificado
                        </h5>
                    )}
                </div>
            }
        >
            {tabModal === "Editar perfil" && (
                <>
                    {load ? (
                        <div className="flex flex-col items-center">
                            <ClipLoader size={20} loading={load} />
                        </div>
                    ) : (
                        <>
                            {entity && (
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        name: entity.name,
                                        companyName: entity.companyName,
                                        documentNumber: entity.documentNumber,
                                        stateRegistrationNumber: entity.stateRegistrationNumber,
                                        isParentCompany: entity.isParentCompany,
                                        powerbiUrl: entity.powerbiUrl,
                                        conexosId: entity.conexosId,
                                        city: entity.city,
                                        codeState: entity.codeState,
                                        parent: entity?.parent
                                            ? { label: entity.parent.name, value: entity.parent?.identifier }
                                            : "",
                                    }}
                                    validationSchema={yup.object().shape({
                                        name: yup.string().required("Campo obrigatório"),
                                        companyName: yup.string(),
                                        documentNumber: yup.string().required("Campo obrigatório"),
                                        stateRegistrationNumber: yup.string(),
                                        powerbiUrl: yup.string(),
                                        conexosId: yup.string(),
                                        city: yup.string().required("Campo obrigatório"),
                                        codeState: yup.string().required("Campo obrigatório"),
                                    })}
                                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                                        setSubmitting(true);
                                        requests
                                            .editClient(selectedEntity.identifier, { ...values, parent: values.parent?.value, isParentCompany: values.isParentCompany?.value })
                                            .then(() => {
                                                handleSuccess();
                                                setSubmitting(false);
                                                toast.success("Informações alteradas com sucesso!");
                                            })
                                            .catch(error => {
                                                setSubmitting(false);
                                            });
                                    }}>
                                    {({
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        errors,
                                        values,
                                        touched,
                                        handleBlur,
                                        setFieldValue,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <ReactTooltip />
                                            <div className="mx-5">
                                                {entity && entity.identifier.length > 0 && entity.documentNumber.length !== 8  && <>
                                                    <div className="flex">
                                                        <label htmlFor="name" className="text-roxo_oficial font-bold">
                                                            Raiz do CNPJ
                                                        </label>
                                                        <span
                                                            className="text-roxo_oficial font-bold ml-2"
                                                            data-tip="Selecionar Raiz do CNPJ"
                                                            data-delay-hide="100">
                                                            <FaQuestionCircle />
                                                        </span>
                                                    </div>
                                                    <Select
                                                        isClearable
                                                        name="parent"
                                                        value={values.parent}
                                                        className="block uppercase text-blueGray-600 text-xs font-bold"
                                                        placeholder="Selecione a Raiz do CNPJ"
                                                        onChange={option => {
                                                            setFieldValue("parent", option);
                                                            if (option) {
                                                                setFieldValue("documentNumber", option.documentNumber);
                                                                values.documentNumber = option.documentNumber;
                                                            }
                                                        }}
                                                        options={parentOptions}
                                                    />
                                                    <ErrorMessage
                                                        component="label"
                                                        name="parent"
                                                        className="text-red font-light w-full"
                                                    />
                                                </>}

                                                <label htmlFor="documentNumber" className="text-roxo_oficial font-bold mt-4">
                                                    CNPJ
                                                </label>
                                                <InputMasked
                                                    name="documentNumber"
                                                    id="documentNumber"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.documentNumber}
                                                    mask="99.999.999-9999/99"
                                                />
                                                <ErrorMessage
                                                    component="label"
                                                    name="documentNumber"
                                                    className="text-red font-light w-full"
                                                />

                                                <label htmlFor="stateRegistrationNumber" className="text-roxo_oficial font-bold mt-4">
                                                    Inscrição Estadual <small>(opcional)</small>
                                                </label>
                                                <Input
                                                    name="stateRegistrationNumber"
                                                    id="stateRegistrationNumber"
                                                    onChange={handleChange}
                                                    value={values.stateRegistrationNumber}
                                                    placeholder="Preencha a Inscrição Estadual"
                                                />
                                                <ErrorMessage component="label" name="stateRegistrationNumber" className="text-red font-light w-full" />

                                                {entity && entity.identifier.length > 0 && entity.documentNumber.length !== 8  && <>
                                                    <label htmlFor="isParentCompany" className="text-roxo_oficial font-bold mt-4">
                                                        É a matriz?
                                                    </label>
                                                    <Select
                                                        isClearable
                                                        name="isParentCompany"
                                                        value={values.isParentCompany}
                                                        className="block uppercase text-blueGray-600 text-xs font-bold"
                                                        placeholder="Selecione a Sim se este for o CNPJ da matriz"
                                                        onChange={option => {
                                                            setFieldValue("isParentCompany", option);
                                                        }}
                                                        options={[
                                                            {
                                                                label: 'Sim',
                                                                value: true,
                                                            },
                                                            {
                                                                label: 'Não',
                                                                value: false,
                                                            }
                                                        ]}
                                                    />
                                                    <ErrorMessage
                                                        component="label"
                                                        name="isParentCompany"
                                                        className="text-red font-light w-full"
                                                    />
                                                </>}

                                                <label htmlFor="name" className="text-roxo_oficial font-bold  mt-4">
                                                    Razão Social
                                                </label>
                                                <Input name="name" id="name" onChange={handleChange} value={values.name} />
                                                <ErrorMessage component="label" name="name" className="text-red font-light w-full" />
                                                <label htmlFor="companyName" className="text-roxo_oficial font-bold mt-4">
                                                    Nome fantasia <small>(opcional)</small>
                                                </label>
                                                <Input
                                                    name="companyName"
                                                    id="companyName"
                                                    onChange={handleChange}
                                                    value={values.companyName}
                                                />
                                                <ErrorMessage
                                                    component="label"
                                                    name="companyName"
                                                    className="text-red font-light w-full"
                                                />

                                                <label htmlFor="city" className="text-roxo_oficial font-bold mt-4">
                                                    Cidade
                                                </label>
                                                <Input name="city" id="city" onChange={handleChange} value={values.city}
                                                    placeholder="Preencha a cidade" />
                                                <ErrorMessage component="label" name="city" className="text-red font-light w-full" />

                                                <label htmlFor="codeState" className="text-roxo_oficial font-bold mt-4">
                                                    Estado (UF)
                                                </label>
                                                <Input
                                                    name="codeState"
                                                    id="codeState"
                                                    onChange={handleChange}
                                                    value={values.codeState}
                                                    placeholder="Preencha o estado (UF)"
                                                />
                                                <ErrorMessage
                                                    component="label"
                                                    name="codeState"
                                                    className="text-red font-light w-full"
                                                />

                                                <label htmlFor="powerbiUrl" className="text-roxo_oficial font-bold mt-4">
                                                    Power BI URL <small>(opcional)</small>
                                                </label>
                                                <Input
                                                    name="powerbiUrl"
                                                    id="powerbiUrl"
                                                    onChange={handleChange}
                                                    value={values.documentNumber}
                                                />
                                                <ErrorMessage
                                                    component="label"
                                                    name="powerbiUrl"
                                                    className="text-red font-light w-full"
                                                />

                                                <label htmlFor="conexosId" className="text-roxo_oficial font-bold mt-4">
                                                    Conexos ID <small>(opcional)</small>
                                                </label>
                                                <Input
                                                    name="conexosId"
                                                    id="conexosId"
                                                    onChange={handleChange}
                                                    value={values.documentNumber}
                                                />
                                                <ErrorMessage
                                                    component="label"
                                                    name="conexosId"
                                                    className="text-red font-light w-full"
                                                />
                                            </div>
                                            <div className="flex justify-end mt-10">
                                                <button
                                                    className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md"
                                                    type="submit"
                                                    disabled={isSubmitting}>
                                                    {isSubmitting ? (
                                                        <>
                                                            Salvando <ClipLoader size={10} loading={isSubmitting} />
                                                        </>
                                                    ) : (
                                                        <>Salvar</>
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            )}
                        </>
                    )}
                </>
            )}
            {tabModal === "Upload de certificado" && entity?.type === "Filial" && (
                <>
                    <ContentCertificate entity={entity}/>
                </>
            )}
        </Modal>
    );
};
export default ModalEdit;